/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { EventDetailsFragmentDoc } from '../fragments/eventDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type DeleteEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type DeleteEventMutation = {
  __typename?: 'Mutation'
  deleteEvent: {
    __typename?: 'EventDeletionResult'
    ok?: boolean | null
    error?: {
      __typename?: 'EventDeletionError'
      code: Types.EventDeletionErrorCode
      description: string
    } | null
  }
}

export type UntagFromEventMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input']
  profileId: Types.Scalars['ID']['input']
}>

export type UntagFromEventMutation = {
  __typename?: 'Mutation'
  untagFromEvent: {
    __typename?: 'EventUntaggingResult'
    ok?: boolean | null
    error?: {
      __typename?: 'EventUntaggingError'
      code: Types.EventUntaggingErrorCode
      description: string
    } | null
  }
}

export type CreateEventMutationVariables = Types.Exact<{
  event: Types.CreateEventInput
}>

export type CreateEventMutation = {
  __typename?: 'Mutation'
  createEvent?: {
    __typename?: 'EventCreationResult'
    ok?: {
      __typename?: 'Event'
      id: string
      published?: boolean | null
      slug: string
      name?: string | null
      description?: string | null
      picture?: string | null
      startTime: string
      isSaved?: boolean | null
      venueAccepted?: boolean | null
      artistIdsAccepted: Array<string>
      promoterAccepted?: boolean | null
      allParticipantsAccepted?: boolean | null
      products: Array<{
        __typename?: 'Product'
        id: string
        name: string
        quantity: number
        price: { __typename?: 'Price'; amount: number }
      }>
      venue?: {
        __typename?: 'Venue'
        id: string
        name: string
        slug: string
        picture?: string | null
        ageRestriction?: Types.AgeRestriction | null
        city: { __typename?: 'City'; id: number; name: string }
      } | null
      genres: Array<{ __typename?: 'Genre'; id: number; name: string } | null>
      artists: Array<{
        __typename?: 'Artist'
        id: string
        name: string
        slug: string
        picture?: string | null
        genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
      }>
      promoter?: {
        __typename: 'Promoter'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      } | null
    } | null
    error?: {
      __typename?: 'EventCreationError'
      code: Types.EventCreationErrorCode
      description: string
    } | null
  } | null
}

export const DeleteEventDocument = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      ok
      error {
        code
        description
      }
    }
  }
`
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options,
  )
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>
export type DeleteEventMutationResult =
  Apollo.MutationResult<DeleteEventMutation>
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>
export const UntagFromEventDocument = gql`
  mutation untagFromEvent($eventId: ID!, $profileId: ID!) {
    untagFromEvent(eventId: $eventId, profileId: $profileId) {
      ok
      error {
        code
        description
      }
    }
  }
`
export type UntagFromEventMutationFn = Apollo.MutationFunction<
  UntagFromEventMutation,
  UntagFromEventMutationVariables
>

/**
 * __useUntagFromEventMutation__
 *
 * To run a mutation, you first call `useUntagFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUntagFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [untagFromEventMutation, { data, loading, error }] = useUntagFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useUntagFromEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UntagFromEventMutation,
    UntagFromEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UntagFromEventMutation,
    UntagFromEventMutationVariables
  >(UntagFromEventDocument, options)
}
export type UntagFromEventMutationHookResult = ReturnType<
  typeof useUntagFromEventMutation
>
export type UntagFromEventMutationResult =
  Apollo.MutationResult<UntagFromEventMutation>
export type UntagFromEventMutationOptions = Apollo.BaseMutationOptions<
  UntagFromEventMutation,
  UntagFromEventMutationVariables
>
export const CreateEventDocument = gql`
  mutation createEvent($event: CreateEventInput!) {
    createEvent(event: $event) {
      ok {
        ...EventDetails
      }
      error {
        code
        description
      }
    }
  }
  ${EventDetailsFragmentDoc}
`
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  )
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>
